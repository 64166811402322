import browserUpdate from 'browser-update';

/**
 * Проверяет поддерживает ли приложение текущий браузер. В случае успеха
 * вызывает переданный коллбэк
 * @param {Function} successCallBack - коллбэк вызываемый в случае успешной проверки
 * @returns {void}
 */
export const checkBrowser = (successCallBack: () => void): void => {
  let supported = true;

  browserUpdate({
    noclose: true,
    onshow: () => {
      supported = false;
    },
    reminder: 0,
    required: {
      /**
       * Android Browser
       */
      a: '> 0',

      /**
       * Chrome
       */
      c: '> 0',

      /**
       * Edge
       */
      e: '> 0',

      /**
       * Firefox
       */
      f: '> 0',

      /**
       * Internet Explorer
       */
      i: 12,

      /**
       * iOS browser
       */
      ios: '> 0',

      /**
       * Opera
       */
      o: '> 0',

      /**
       * Opera (Android)
       */
      o_a: '> 0',

      /**
       * Safari
       */
      s: '> 0',

      /**
       * Samsung Internet (Android)
       */
      samsung: '> 0',

      /**
       * UC Browser
       */
      uc: '> 0',

      /**
       * Vivaldi
       */
      v: '> 0',

      /**
       * Yandex Browser
       */
      y: '> 0',
    },
  });

  if (supported) successCallBack();
};

export default checkBrowser;
